.App {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(0, -50%) !important;
}
